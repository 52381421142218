import { useLocation } from "@reach/router";
import { parse } from "query-string";
import classNames from "classnames";
import { useFeatureFlagVariantKey, usePostHog } from "posthog-js/react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import {
  BiCart,
  BiChevronDown,
  BiCircle,
  BiMinus,
  BiPlus,
  BiSolidCheckCircle,
} from "react-icons/bi";
import { IoMdCheckbox, IoMdSquareOutline } from "react-icons/io";
import SlideToggle from "react-slide-toggle";
import { v4 as uuidv4 } from "uuid";
import tailwindConfig from "../../tailwind.config";
import CompareProductsModal from "../components/partials/compare-products-modal";
import DescriptionModal from "../components/partials/description-modal";
import Star from "../components/partials/star";
import CartContext from "../context/cart-context";
import useLinerProduct from "../hooks/useLinerProduct";
import useRushOrderProduct from "../hooks/useRushOrderProduct";
import IconInstallation from "../images/icon-installation.png";
import IconMeasure from "../images/icon-measure.png";
import IconOrder from "../images/icon-order.png";
import IconQuantity from "../images/icon-quantity.png";
import IconRush from "../images/icon-rush.png";
import { Price, SupDollarSign } from "../utilities/price";

const fillColor =
  process.env.THEME_COLOR_PRIMARY === "green"
    ? tailwindConfig.theme.colors.green["darker"]
    : tailwindConfig.theme.colors.yellow["dark"];

const componentStyles = {
  addToCartBox: {
    Wasatch: `bg-${process.env.THEME_COLOR_PRIMARY}-dark text-white`,
    Mountainland: `bg-${process.env.THEME_COLOR_PRIMARY} text-black`,
  },
  addToCartButton: {
    Wasatch: `bg-white text-${process.env.THEME_COLOR_PRIMARY}-dark`,
    Mountainland: "bg-black text-white",
  },
  priceText: {
    Wasatch: `text-${process.env.THEME_COLOR_PRIMARY}`,
    Mountainland: `text-${process.env.THEME_COLOR_PRIMARY}-dark`,
  },
  saleText: {
    Wasatch:
      "font-medium uppercase bg-orange-lighter text-orange px-6 py-2 rounded-full",
    Mountainland:
      "font-medium uppercase bg-green-lighter text-green-darker px-6 py-2 rounded-full",
  },
  textLink: {
    Wasatch: `text-${process.env.THEME_COLOR_PRIMARY}-dark`,
    Mountainland: `text-${process.env.THEME_COLOR_PRIMARY}-darker`,
  },
};

function VariableProductTemplate(props) {
  const posthog = usePostHog();
  const addCompareProductsModalKey = useFeatureFlagVariantKey(
    "add-compare-products-modal"
  );
  const addCoverModelSelectionKey = useFeatureFlagVariantKey(
    "cover-model-selection"
  );
  const variations = props.currentProduct.product.variations;
  const cartContext = useContext(CartContext);
  const location = useLocation();
  const rushOrderProduct = useRushOrderProduct();
  const linerProduct = useLinerProduct();
  const [selectedVariation, setSelectedVariation] = useState(0);
  const [selectedVariationMaterial, setSelectedVariationMaterial] =
    useState("");
  const [selectedVariationColor, setSelectedVariationColor] = useState("");
  const [selectedVariationStyle, setSelectedVariationStyle] = useState("");
  const [selectedLiner, setSelectedLiner] = useState(null);
  const [selectedAccessories, setSelectedAccessories] = useState([]);
  const [selectedRush, setSelectedRush] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [notes, setNotes] = useState("");

  const liners = linerProduct ? linerProduct.node.product.variations : [];
  const accessoriesData = cartContext.accessoryProducts();

  const accessories = useMemo(() => {
    if (!accessoriesData) return [];
    const mappedAccessories = accessoriesData.map((acc) => {
      return { ...acc.node };
    });
    const filteredAccessories = mappedAccessories.filter((accessory) => {
      return accessory.id !== "cG9zdDo4MDIzNw==";
    });

    if (
      selectedVariationStyle !== "polycarbonate-topper" &&
      selectedVariationMaterial !== "polycarbonate"
    ) {
      const topper = mappedAccessories.find(
        (acc) => acc.id === "cG9zdDo4MDIzNw=="
      );

      return [topper, ...filteredAccessories];
    }

    return filteredAccessories;
  }, [selectedVariationStyle, selectedVariationMaterial, accessoriesData]);

  const initialCoverModels = useMemo(() => {
    const skus = [
      "window-well-cover-steel-black",
      "window-well-cover-polycarbonate",
      "window-well-cover-steel-black-poly-cover",
    ];
    return variations.filter((variation) => {
      return skus.includes(variation.variationSku);
    });
  }, [variations]);

  const increment = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  useEffect(() => {
    setNotes(cartContext.checkoutInfo.whichWellsNotes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = parse(location.search);
    const style = queryParams.style || "standard";
    const material = queryParams.material || "steel";
    const color = queryParams.color || "black";
    setSelectedVariationStyle(style);
    setSelectedVariationMaterial(material);
    setSelectedVariationColor(color);

    const find = variations.find((variation) => {
      return (
        variation.variationBase === material &&
        variation.variationColor === color &&
        variation.variationStyle === style
      );
    });

    if (!find) return;

    return setSelectedVariation(find);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (!selectedVariation) return;
    props.updateGallery(selectedVariation.variationImageGallery);
    props.updateVariation(selectedVariation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariation]);

  const total = useMemo(() => {
    if (!selectedVariation) return 0;
    const coverPrice = selectedVariation.variationSalePrice
      ? selectedVariation.variationSalePrice
      : selectedVariation.variationBasePrice;

    let linerPrice = 0;
    if (selectedLiner) {
      linerPrice = selectedLiner.variationSalePrice
        ? selectedLiner.variationSalePrice
        : selectedLiner.variationBasePrice;
    }
    const accessoriesPrice = selectedAccessories.reduce((accumulator, item) => {
      let price = item.product.salePrice
        ? item.product.salePrice
        : item.product.basePrice;
      return +accumulator + +price;
    }, 0);

    const rushPrice = selectedRush ? 100 : 0;

    return (
      (+coverPrice + +linerPrice + +accessoriesPrice) * +quantity + rushPrice
    );
  }, [
    selectedVariation,
    selectedLiner,
    selectedAccessories,
    selectedRush,
    quantity,
  ]);

  function getSaleText() {
    const percentOff =
      100 -
      (selectedVariation.variationSalePrice /
        selectedVariation.variationBasePrice) *
        100;
    return percentOff >= 35 ? (
      <span>More than 35% OFF</span>
    ) : (
      <span>{percentOff.toFixed(0)}% OFF</span>
    );
  }

  const handleSelectOption = (optionSku) => {
    const model = initialCoverModels.find(
      (model) => model.variationSku === optionSku
    );
    if (model) {
      setSelectedVariation(model);
      setSelectedVariationColor(model.variationColor);
      setSelectedVariationMaterial(model.variationBase);
      setSelectedVariationStyle(model.variationStyle);
    }
  };

  function sendGtagEvent() {
    if (typeof window === "undefined" || !window.gtag) return;

    const products = [];

    if (selectedVariation) {
      products.push({
        item_id: selectedVariation.variationSku,
        item_name: selectedVariation.variationName,
        category: "covers",
        price:
          selectedVariation.variationSalePrice ??
          selectedVariation.variationBasePrice,
        quantity,
        item_variant: selectedVariationColor,
      });
    }

    if (selectedLiner) {
      products.push({
        item_id: selectedLiner.variationSku,
        item_name: selectedLiner.variationName,
        category: "liners",
        price:
          selectedLiner.variationSalePrice ?? selectedLiner.variationBasePrice,
        quantity: quantity,
        item_variant: selectedLiner.variationName,
      });
    }

    for (const acc of selectedAccessories) {
      products.push({
        item_id: acc.product.sku,
        item_name: acc.title,
        category: "accessories",
        price: acc.product.salePrice ?? acc.product.basePrice,
        quantity: quantity,
      });
    }

    if (selectedRush) {
      products.push({
        item_id: rushOrderProduct.node.product.sku,
        item_name: rushOrderProduct.node.title,
        category: "other",
        price:
          rushOrderProduct.node.product.salePrice ??
          rushOrderProduct.node.product.basePrice,
        quantity: quantity,
      });
    }

    const eventData = {
      currency: "USD",
      value: products.reduce((acc, product) => {
        return acc + product.price * product.quantity;
      }, 0),
      items: products.map((product, index) => {
        return {
          ...product,
          index: index,
        };
      }),
    };

    console.info("add_to_cart", eventData);

    try {
      window.gtag("event", "add_to_cart", eventData);
    } catch (error) {
      console.error("gtag error", error);
    }

    try {
      posthog.capture("add_to_cart", eventData);
    } catch (error) {
      console.error("posthog error", error);
    }
  }

  function addProductsToCart() {
    const bundleId = uuidv4();

    if (selectedVariation) {
      const cover = {
        id: selectedVariation.variationSku,
        skus: (selectedVariation.variationSkus || []).map(
          (variationSku) => variationSku.variationSkusSku
        ),
        bundleId: bundleId,
        taxable: true,
        title: selectedVariation.variationName,
        category: ["covers"],
        basePrice: selectedVariation.variationBasePrice,
        salePrice: selectedVariation.variationSalePrice,
        quantity: quantity,
        url: `/window-well-covers/?material=${selectedVariationMaterial}&color=${selectedVariationColor}$=&style=${selectedVariationStyle}`,
        image: selectedVariation.variationFeaturedImage.localFile.publicURL,
        color: selectedVariationColor,
      };
      cartContext.addItemToCart(cover);
    }

    if (selectedLiner) {
      const liner = {
        id: selectedLiner.variationSku,
        skus: (selectedLiner.variationSkus || []).map(
          (variationSku) => variationSku.variationSkusSku
        ),
        bundleId: bundleId,
        taxable: true,
        title: selectedLiner.variationName,
        category: ["liners"],
        basePrice: selectedLiner.variationBasePrice,
        salePrice: selectedLiner.variationSalePrice,
        quantity: quantity,
        url: `/window-well-liners/style=${selectedLiner.variationName}`,
        image: selectedLiner.variationFeaturedImage.localFile.publicURL,
      };
      cartContext.addItemToCart(liner);
    }

    selectedAccessories.map((acc) => {
      const accessory = {
        id: acc.product.sku,
        skus: (acc.product.variationSkus || []).map(
          (variationSku) => variationSku.variationSkusSku
        ),
        bundleId: bundleId,
        taxable: true,
        title: acc.title,
        category: ["accessories"],
        basePrice: acc.product.basePrice,
        salePrice: acc.product.salePrice,
        quantity: quantity,
        url: `/${acc.slug}/`,
        image: acc.featuredImage.node.localFile.publicURL,
      };

      return cartContext.addItemToCart(accessory);
    });

    if (selectedRush) {
      const rush = {
        id: rushOrderProduct.node.product.sku,
        skus: (rushOrderProduct.node.product.variationSkus || []).map(
          (variationSku) => variationSku.variationSkusSku
        ),
        taxable: rushOrderProduct.node.product.taxable,
        title: rushOrderProduct.node.title,
        category: ["other"],
        basePrice: rushOrderProduct.node.product.basePrice,
        salePrice: rushOrderProduct.node.product.salePrice,
        quantity: quantity,
        url: `/${rushOrderProduct.node.slug}/`,
        image: rushOrderProduct.node.featuredImage.node.localFile.publicURL,
      };
      cartContext.addItemToCart(rush);
    }

    cartContext.checkoutInfo.whichWellsNotes = notes;

    cartContext.setCartDrawerOpenStatus(true);

    sendGtagEvent();
  }

  if (!selectedVariation) {
    return <p>No product selected</p>;
  }

  return (
    <div>
      <Helmet>
        <body data-cover-page="true" />
      </Helmet>
      <h1
        className="mt-0 mb-3 font-semibold text-lg lg:text-3xl text-black"
        dangerouslySetInnerHTML={{
          __html: selectedVariation.variationName,
        }}
      />
      <div className="flex justify-start items-center w-full mb-4">
        <a
          href={process.env.GOOGLE_REVIEWS_PAGE}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={`text-${process.env.THEME_COLOR_PRIMARY}-dark no-underline flex items-center w-24`}
          title="5 Star Google Review"
          aria-label="stars"
        >
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </a>
        <a
          href={process.env.GOOGLE_REVIEWS_PAGE}
          className={`ml-2 pt-1 no-underline text-grey-darkest hover:underline`}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          See reviews
        </a>
      </div>
      <p className="text-grey-dark">{process.env.TURNAROUND_PHRASE}</p>
      <div className="flex w-full gap-4 items-center mt-8 lg:mt-10 mb-4">
        <div
          className={classNames(
            `text-lg lg:text-2xl font-medium text-${process.env.THEME_COLOR_PRIMARY}-darker`
          )}
        >
          <Price value={selectedVariation.variationSalePrice} />
        </div>
        <div className="line-through text-lg lg:text-2xl font-medium text-grey">
          <Price value={selectedVariation.variationBasePrice} />
        </div>
        <div
          className={classNames(
            `font-medium uppercase px-6 py-2 rounded-full`,
            componentStyles.saleText[process.env.COMPANY_NAME_SHORTER]
          )}
        >
          {getSaleText()}
        </div>
      </div>

      <p className="mb-10">
        Sale Ends {props.saleEnds.date} &bull; {props.saleEnds.days} Days Left!
        <span className="text-grey-dark ml-4">*Online Only</span>
      </p>

      <div className="mb-4 leading-loose">
        <h4 className="font-bold">Description</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: selectedVariation.variationShortDescription,
          }}
          className="wp-content inline text-grey-darker leading-loose"
        />
        <DescriptionModal
          header={selectedVariation.variationName}
          description={selectedVariation.variationContent}
        />
      </div>
      <div className="mb-10 leading-loose">
        <OurProcessContent />
      </div>
      <h3 className="text-lg lg:text-xl font-bold">Cover Model</h3>
      <div className="text-grey-dark mb-2">
        Select Window Well Cover model type
      </div>
      {addCoverModelSelectionKey === "test" && (
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-3">
          {initialCoverModels &&
            initialCoverModels.map((model) => {
              return (
                <button
                  key={model.variationSku}
                  className={`relative flex flex-col text-left p-2 xl:p-4 rounded-lg ${
                    selectedVariation.variationSku === model.variationSku
                      ? `border-2 border-${process.env.THEME_COLOR_PRIMARY}-dark`
                      : "border border-grey-light"
                  }`}
                  onClick={() => {
                    setSelectedVariation(model);
                    setSelectedVariationColor(model.variationColor);
                    setSelectedVariationMaterial(model.variationBase);
                    setSelectedVariationStyle(model.variationStyle);
                  }}
                >
                  <img
                    alt={model.variationName}
                    src={model.variationFeaturedImage.localFile.publicURL}
                    className="block w-full h-36 sm:h-24 md:h-36 lg:h-24 object-cover rounded-lg mb-3"
                  />
                  <h4 className="text-sm font-bold mb-2">
                    {model.variationName}
                  </h4>
                  <div>
                    <Price value={model.variationSalePrice} />
                  </div>
                  <div className="text-xs text-grey-darker">
                    One price any size up to 44" x 84"
                  </div>
                  {selectedVariation.variationSku === model.variationSku && (
                    <BiSolidCheckCircle
                      size={25}
                      fill={fillColor}
                      className="absolute top-6 right-6 bg-white rounded-full"
                    />
                  )}
                </button>
              );
            })}
        </div>
      )}
      {addCoverModelSelectionKey !== "test" && (
        <SlideToggle collapsed={true}>
          {({ toggle, setCollapsibleElement, toggleState }) => (
            <div className="border border-grey-light rounded-lg">
              <button
                className={classNames(
                  "my-collapsible__toggle w-full text-left p-4 flex items-center",
                  "lg:h-24",
                  toggleState === "EXPANDED"
                    ? "border-b border-b-grey-light bg-grey-light"
                    : "hover:bg-grey-light"
                )}
                onClick={toggle}
              >
                <img
                  alt={selectedVariation?.variationName}
                  src={
                    selectedVariation?.variationFeaturedImage?.localFile
                      .publicURL
                  }
                  className="block w-12 h-12 object-cover mr-4 rounded"
                />
                <div className="flex-1">
                  <h4 className="font-bold">
                    {selectedVariation.variationName}
                  </h4>
                  <div>
                    <span className="mr-2">
                      <Price value={selectedVariation.variationSalePrice} />
                    </span>
                    <span className="text-sm text-grey-dark">
                      One price any size up to 44" x 84"
                    </span>
                  </div>
                </div>
                <BiChevronDown size={25} />
              </button>
              <div ref={setCollapsibleElement}>
                <div>
                  {initialCoverModels &&
                    initialCoverModels.map((model) => {
                      return (
                        <button
                          key={model.variationSku}
                          className={`w-full text-left p-4 flex items-center lg:h-24 hover:bg-grey-lighter `}
                          onClick={() => {
                            toggle();
                            setSelectedVariation(model);
                            setSelectedVariationColor(model.variationColor);
                            setSelectedVariationMaterial(model.variationBase);
                            setSelectedVariationStyle(model.variationStyle);
                          }}
                        >
                          <img
                            alt={model.variationName}
                            src={
                              model.variationFeaturedImage.localFile.publicURL
                            }
                            className="block w-12 h-12 object-cover mr-4 rounded"
                          />
                          <div className="flex-1">
                            <h4 className="font-bold">{model.variationName}</h4>
                            <div>
                              <span className="mr-2">
                                <Price value={model.variationSalePrice} />
                              </span>
                              <span className="text-sm text-grey-dark">
                                One price any size up to 44" x 84"
                              </span>
                            </div>
                          </div>
                          {selectedVariation.variationSku ===
                            model.variationSku && (
                            <BiSolidCheckCircle size={25} fill={fillColor} />
                          )}
                        </button>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </SlideToggle>
      )}

      {addCompareProductsModalKey === "modal-enabled" && (
        <div className="mt-2">
          <CompareProductsModal
            header="Need help deciding?"
            handleSelectOptionBySku={handleSelectOption}
            learnMoreText="Need help deciding?"
          />
        </div>
      )}

      {selectedVariation.variationBase !== "polycarbonate" && (
        <>
          <h3 className="text-lg lg:text-xl font-bold mt-8">Color</h3>
          <div className="text-grey-dark mb-2">
            Select the color thats right for you
          </div>
          <div className="grid lg:grid-cols-2 gap-3">
            <button
              className={`p-4 border rounded-lg flex items-center gap-4 hover:bg-grey-lighter ${
                selectedVariationColor === "black"
                  ? `border-${process.env.THEME_COLOR_PRIMARY}-dark`
                  : "border-grey-light"
              }`}
              onClick={() => {
                setSelectedVariationColor("black");
              }}
            >
              <div className="h-12 w-12 rounded-full bg-black"></div>
              <div className="flex-1 text-left">
                <div className="font-bold">Black</div>
              </div>
              {selectedVariationColor === "black" ? (
                <BiSolidCheckCircle size={25} fill={fillColor} />
              ) : (
                <BiCircle size={25} fill="#dae1e7" />
              )}
            </button>
            <button
              className={`p-4 border rounded-lg flex items-center gap-4 hover:bg-grey-lighter ${
                selectedVariationColor === "brown"
                  ? `border-${process.env.THEME_COLOR_PRIMARY}-dark`
                  : "border-grey-light"
              }`}
              onClick={() => {
                setSelectedVariationColor("brown");
              }}
            >
              <div className="h-12 w-12 rounded-full bg-brown"></div>
              <div className="flex-1 text-left">
                <div className="font-bold">Brown</div>
                <div className="text-grey-dark">+ $20.00</div>
              </div>
              {selectedVariationColor === "brown" ? (
                <BiSolidCheckCircle size={25} fill={fillColor} />
              ) : (
                <BiCircle size={25} fill="#dae1e7" />
              )}
            </button>
          </div>
        </>
      )}
      <h3 className="text-lg lg:text-xl font-bold mt-8 flex items-center">
        <span
          className={`font-medium bg-orange-lighter text-orange px-3 py-1 rounded-full text-sm mr-3`}
        >
          New!
        </span>
        Window Well Liner
      </h3>
      <div className="text-grey-dark mb-2">
        Select a window well liner (optional){" "}
      </div>
      <div>
        {liners &&
          liners.map((liner) => {
            return (
              <button
                key={liner.variationSku}
                className={`p-4 border rounded-lg flex items-center gap-4 hover:bg-grey-lighter w-full mb-3 text-left ${
                  selectedLiner?.variationSku === liner.variationSku
                    ? `border-${process.env.THEME_COLOR_PRIMARY}-dark`
                    : "border-grey-light"
                }`}
                onClick={() => {
                  setSelectedLiner(() => {
                    return selectedLiner === liner ? null : liner;
                  });
                }}
              >
                <img
                  alt={liner.variationName}
                  src={liner.variationFeaturedImage.localFile.publicURL}
                  className="block w-12 h-12 object-cover mr-4 rounded"
                />
                <div className="flex-1">
                  <h4 className="font-bold">{liner.variationName}</h4>
                  <div>
                    <span className="text-grey-dark mr-2">
                      + <Price value={liner.variationSalePrice} />
                    </span>
                    <DescriptionModal
                      header={liner.variationName}
                      description={liner.variationContent}
                      image={liner.variationFeaturedImage.localFile.publicURL}
                    />
                    {liner.outOfStock && liner.outOfStockShortDescription && (
                      <span className="ml-2 text-grey-dark">
                        {liner.outOfStockShortDescription}
                      </span>
                    )}
                  </div>
                </div>
                {selectedLiner?.variationSku === liner.variationSku ? (
                  <BiSolidCheckCircle size={25} fill={fillColor} />
                ) : (
                  <BiCircle size={25} fill="#dae1e7" />
                )}
              </button>
            );
          })}
        {selectedLiner?.outOfStock &&
          selectedLiner?.outOfStockLongDescription && (
            <div className="my-3 p-2 border border-red text-red">
              {selectedLiner.outOfStockLongDescription}
            </div>
          )}
      </div>

      <h3 className="text-lg lg:text-xl font-bold mt-8">Accessories</h3>
      <div className="text-grey-dark mb-2">
        Select any window well accessories (optional)
      </div>
      <div>
        {accessories &&
          accessories.map((accessory) => {
            if (!accessory?.id) return null;

            return (
              <button
                key={accessory.id}
                className={`p-4 border rounded-lg flex items-center gap-4 hover:bg-grey-lighter w-full mb-3 text-left ${
                  selectedAccessories.find((acc) => acc.id === accessory.id)
                    ? `border-${process.env.THEME_COLOR_PRIMARY}-dark`
                    : "border-grey-light"
                }`}
                onClick={() => {
                  if (
                    selectedAccessories.find((acc) => acc.id === accessory.id)
                  ) {
                    setSelectedAccessories(
                      selectedAccessories.filter(
                        (acc) => acc.id !== accessory.id
                      )
                    );
                  } else {
                    setSelectedAccessories([...selectedAccessories, accessory]);
                  }
                }}
              >
                <img
                  alt={accessory.title}
                  src={accessory.featuredImage.node.localFile.publicURL}
                  className="block w-12 h-12 object-cover mr-4 rounded"
                />
                <div className="flex-1">
                  <h4 className="font-bold">{accessory.title}</h4>
                  <div>
                    <span className="text-grey-dark mr-2">
                      +{" "}
                      <Price
                        value={
                          accessory.product.salePrice
                            ? accessory.product.salePrice
                            : accessory.product.basePrice
                        }
                      />
                    </span>
                    <DescriptionModal
                      header={accessory.title}
                      description={accessory.product.content}
                      image={accessory.featuredImage.node.localFile.publicURL}
                    />
                  </div>
                </div>
                {selectedAccessories.find((acc) => acc.id === accessory.id) ? (
                  <IoMdCheckbox size={25} fill={fillColor} />
                ) : (
                  <IoMdSquareOutline size={25} fill="#dae1e7" />
                )}
              </button>
            );
          })}
      </div>

      <h3 className="text-lg lg:text-xl font-bold mt-8">Final Details</h3>
      <div className="text-grey-dark mb-2">
        Complete your order bundle and leave a note for our technician to
        enhance the measuring process during their post-order visit to measure
        your window wells.
      </div>

      <div className="flex items-center border border-grey-light bg-white rounded-lg p-3 my-2">
        <img
          src={IconQuantity}
          width={50}
          className="mr-3"
          alt="Quantity icon"
          aria-hidden="true"
        />
        <div className="flex-1">
          <div className="font-medium">Quantity</div>
          <div className="text-grey-dark">
            Choose the quantity for your order.
          </div>
        </div>
        <div className="flex rounded-lg border border-grey-light">
          <button
            className="px-3 py-3 text-xl mx-1"
            onClick={decrement}
            aria-label="decrement"
          >
            <BiMinus />
          </button>
          <input
            type="text"
            className="outline-none w-8 block text-center"
            value={quantity}
            readOnly
          />
          <button
            className="px-3 py-3 text-xl mx-1"
            onClick={increment}
            aria-label="increment"
          >
            <BiPlus />
          </button>
        </div>
      </div>

      <button
        className={`p-4 border rounded-lg flex items-center gap-4 hover:bg-grey-lighter w-full mb-3 text-left ${
          selectedRush
            ? `border-${process.env.THEME_COLOR_PRIMARY}-dark`
            : "border-grey-light"
        }`}
        onClick={() => {
          setSelectedRush(!selectedRush);
        }}
      >
        <img src={IconRush} width={50} alt="Rush icon" aria-hidden="true" />
        <div className="flex-1">
          <div className="font-medium">Rush Order - one week</div>
          <div className="text-grey-dark">
            Need your cover(s) within a week? (optional) <br />+{" "}
            <SupDollarSign />
            100.00
          </div>
        </div>
        {selectedRush ? (
          <BiSolidCheckCircle size={25} fill={fillColor} />
        ) : (
          <BiCircle size={25} fill="#dae1e7" />
        )}
      </button>

      <div className="border border-grey-light bg-white rounded-lg p-3 my-2">
        <div className="font-medium mb-2">Notes for our technician</div>
        <textarea
          placeholder="write here (optional)"
          className="block w-full"
          onChange={(e) => setNotes(e.target.value)}
          value={notes}
        ></textarea>
      </div>

      <h3 className="text-lg lg:text-xl font-bold mt-8">Add to Cart</h3>
      <div className="text-grey-dark mb-2">
        Proceed to checkout with your order or continue browsing.
      </div>
      <AddToCartBox addProductsToCart={addProductsToCart} total={total} />
    </div>
  );
}

const OurProcessContent = () => {
  return (
    <>
      <h2 className="text-lg mb-4 mr-8 lg:mr-0">Our Process</h2>
      <div className="grid grid-cols-3 gap-4 leading-normal">
        <div className="p-4 border border-grey-light rounded-lg">
          <img
            src={IconOrder}
            width={50}
            className="mb-3"
            alt="Icon for step 1"
            aria-hidden="true"
          />
          <div
            className={`font-medium mr-3 text-${process.env.THEME_COLOR_PRIMARY}-dark`}
          >
            Step 1
          </div>
          <div className="text-grey-darker">
            You place your order online or by phone.
          </div>
        </div>
        <div className="p-4 border border-grey-light rounded-lg">
          <img
            src={IconMeasure}
            width={50}
            className="mb-3"
            alt="Icon for step 2"
            aria-hidden="true"
          />
          <div
            className={`font-medium mr-3 text-${process.env.THEME_COLOR_PRIMARY}-dark`}
          >
            Step 2
          </div>
          <div className="text-grey-darker">
            We come measure your wells for your custom-build covers.
          </div>
        </div>
        <div className="p-4 border border-grey-light rounded-lg">
          <img
            src={IconInstallation}
            width={50}
            className="mb-3"
            alt="Icon for step 3"
            aria-hidden="true"
          />
          <div
            className={`font-medium mr-3 text-${process.env.THEME_COLOR_PRIMARY}-dark`}
          >
            Step 3
          </div>
          <div className="text-grey-darker">
            We return to install your custom build covers & accessories.
          </div>
        </div>
      </div>
    </>
  );
};

const AddToCartBox = ({ addProductsToCart, total }) => {
  return (
    <div
      className={classNames(
        "rounded-lg p-4 lg:p-6 sticky -bottom-20",
        "lg:pt-3 lg:-bottom-24",
        componentStyles.addToCartBox[process.env.COMPANY_NAME_SHORTER]
      )}
    >
      <div className="flex items-center justify-between gap-8">
        <div className="lg:text-2xl text-lg font-medium">
          Total: <Price value={total} />
        </div>

        <div>
          <button
            onClick={() => {
              addProductsToCart();
            }}
            className={classNames(
              `flex items-center gap-2 px-3 py-2 no-underline rounded`,
              componentStyles.addToCartButton[process.env.COMPANY_NAME_SHORTER]
            )}
          >
            <span>Add To Cart</span>
            <BiCart size={20} />
          </button>
        </div>
      </div>
      <div className="mb-4 mt-4 lg:mt-4">
        <SupDollarSign />
        100 Deposit to start your order today. Deposit is applied to your total
        balance
      </div>
    </div>
  );
};

VariableProductTemplate.propTypes = {
  productAttributes: PropTypes.arrayOf(PropTypes.object),
};

export default VariableProductTemplate;

// border-yellow-dark
// border-green-dark
