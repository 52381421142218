import { useLocation } from "@reach/router";
import Case from "case";
import classNames from "classnames";
import { usePostHog } from "posthog-js/react";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import DescriptionModal from "../components/partials/description-modal";
import Star from "../components/partials/star";
import CartContext from "../context/cart-context";
import { Price, SupDollarSign, formatPrice } from "../utilities/price";
import { styles } from "../utilities/styles";

function VariableProductLinerTemplate(props) {
  const posthog = usePostHog();
  const cartContext = useContext(CartContext);
  const location = useLocation();

  const [selectedProductAttributes, setSelectedProductAttributes] = useState(
    props.currentProduct.product.variations[0].variationAttributes.reduce(
      (acc, attribute) => {
        acc[attribute.variationAttributeName] =
          attribute.variationAttributeValue;
        return acc;
      },
      {}
    )
  );

  useEffect(() => {
    const url = new URL(location?.href);
    url.searchParams.forEach((value, key) => {
      setSelectedProductAttributes((previous) => {
        const shouldUpdate = previous[key] !== value;
        if (!shouldUpdate) return previous;

        return {
          ...previous,
          [key]: value,
        };
      });
    });

    // only run once
  }, [location?.href]);

  const [quantity, setQuantity] = useState(1);

  const findProductBasedOnAttributes = useCallback(
    (productAttributes = {}) => {
      if (!Object.keys(productAttributes).length) {
        productAttributes = selectedProductAttributes;
      }

      const selectedProduct = props.currentProduct.product.variations.filter(
        (variation) => {
          return (
            variation.variationAttributes.filter((attribute) => {
              if (
                productAttributes[attribute.variationAttributeName] ===
                attribute.variationAttributeValue
              ) {
                return true;
              }
              return false;
            }).length === Object.keys(productAttributes).length
          );
        }
      );
      return selectedProduct;
    },
    [props.currentProduct.product.variations, selectedProductAttributes]
  );

  const selectedProduct = useMemo(() => {
    if (!Object.keys(selectedProductAttributes).length) {
      return props.currentProduct.product.variations[0];
    }
    const selectedProduct = findProductBasedOnAttributes();
    if (!selectedProduct.length) {
      return props.currentProduct.product.variations[0];
    }
    return selectedProduct[0];
  }, [
    findProductBasedOnAttributes,
    props.currentProduct.product.variations,
    selectedProductAttributes,
  ]);

  function isAttributeSelectable(key, value) {
    const compareAttributes = {
      ...selectedProductAttributes,
    };
    compareAttributes[key] = value;

    return findProductBasedOnAttributes(compareAttributes).length
      ? true
      : false;
  }

  function getVariationAttributes() {
    let variationAttributes = {};
    props.currentProduct.product.variations.map((variation) => {
      variation.variationAttributes.map((variationAttribute) => {
        if (
          Object.keys(variationAttributes).indexOf(
            variationAttribute.variationAttributeName
          ) >= 0
        ) {
          if (
            variationAttributes[variationAttribute.variationAttributeName][
              "options"
            ].indexOf(variationAttribute.variationAttributeValue) < 0
          ) {
            variationAttributes[variationAttribute.variationAttributeName][
              "options"
            ].push(variationAttribute.variationAttributeValue);
          }
        } else {
          variationAttributes[variationAttribute.variationAttributeName] = {};
          variationAttributes[variationAttribute.variationAttributeName][
            "options"
          ] = [variationAttribute.variationAttributeValue];
        }
        return true;
      });
      return true;
    });
    return variationAttributes;
  }

  useEffect(() => {
    let selectedProduct = findProductBasedOnAttributes();
    if (!selectedProduct.length && variationAttributes.color) {
      variationAttributes.color.options.map((color) => {
        const queryVariationAttributes = {
          material: selectedProductAttributes.material,
          color,
          style: selectedProductAttributes.style,
        };

        if (
          !selectedProduct.length &&
          findProductBasedOnAttributes(queryVariationAttributes).length
        ) {
          selectedProduct = findProductBasedOnAttributes(
            queryVariationAttributes
          );
          setSelectedProductAttributes(queryVariationAttributes);
        }
        return true;
      });
    }

    if (!selectedProduct.length) {
      variationAttributes.style.options.map((style) => {
        const queryVariationAttributes = {
          material: selectedProductAttributes.material,
          color: selectedProductAttributes.color,
          style: style,
        };

        if (
          !selectedProduct.length &&
          findProductBasedOnAttributes(queryVariationAttributes).length
        ) {
          selectedProduct = findProductBasedOnAttributes(
            queryVariationAttributes
          );
          setSelectedProductAttributes(queryVariationAttributes);
        }
        return true;
      });
    }
    if (!selectedProduct.length) {
      selectedProduct = [props.currentProduct.product.variations[0]];
    }
    props.updateGallery(selectedProduct[0].variationImageGallery);
    props.updateVariation(selectedProduct[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductAttributes]);

  function attributeChanged(key, name) {
    setSelectedProductAttributes((previous) => ({
      ...previous,
      [key]: name,
    }));
  }

  function getVariationBaseOnMaterial(material) {
    const applicableColors = variationAttributes.color.options.filter(
      (color) => {
        const queryVariationAttributes = {
          material: material,
          color,
        };

        if (findProductBasedOnAttributes(queryVariationAttributes).length) {
          return true;
        }
        return false;
      }
    );

    const applicableStyles = variationAttributes.style?.options.filter(
      (style) => {
        const queryVariationAttributes = {
          material: material,
          style,
        };

        if (findProductBasedOnAttributes(queryVariationAttributes).length) {
          return true;
        }
        return false;
      }
    );

    return findProductBasedOnAttributes({
      material: material,
      color: applicableColors[0],
      style: applicableStyles ? applicableStyles[0] : "standard",
    })[0];
  }

  function attributePrice(attributeName, attributeValue) {
    let product = {
      variationBasePrice: 0,
      variationSalePrice: 0,
    };
    if (attributeName === "material") {
      product = getVariationBaseOnMaterial(attributeValue);
      return " " + formatPrice(product?.variationSalePrice);
    }
    if (attributeName === "style") {
      product = findProductBasedOnAttributes({
        material: selectedProductAttributes.material,
        color: selectedProductAttributes.color,
        style: attributeValue,
      })[0];
      if (!product) {
        return "";
      }

      return (
        " + " +
        formatPrice(
          product.variationSalePrice - selectedProduct.variationSalePrice
        )
      );
    } else {
      product = findProductBasedOnAttributes({
        material: selectedProductAttributes.material,
        color: attributeValue,
        style: selectedProductAttributes.style,
      })[0];
      if (!product) {
        return "";
      }

      return (
        " + " +
        formatPrice(
          product?.variationSalePrice - selectedProduct.variationSalePrice
        )
      );
    }
  }

  function getSaleText() {
    const percentOff =
      100 -
      (selectedProduct.variationSalePrice /
        selectedProduct.variationBasePrice) *
        100;
    return percentOff >= 35 ? (
      <span>More than 35% OFF</span>
    ) : (
      <span>{percentOff.toFixed(0)}% OFF</span>
    );
  }

  function getOutOfStockText(name) {
    const variationObj = props.currentProduct.product.variations.find(
      (variation) =>
        variation.variationAttributes.some(
          (att) => att.variationAttributeValue === name
        )
    );

    if (!variationObj.outOfStock) {
      return "";
    }

    return ` - ${variationObj.outOfStockShortDescription}`;
  }

  const variationAttributes = getVariationAttributes();

  return (
    <div>
      <h1
        className="mt-0 mb-3 font-semibold text-lg lg:text-3xl text-black"
        dangerouslySetInnerHTML={{
          __html: selectedProduct.variationName,
        }}
      />
      <div className="flex justify-start items-center w-full mb-4">
        <a
          href={process.env.GOOGLE_REVIEWS_PAGE}
          target="_blank"
          rel="noopener noreferrer nofollow"
          className={`text-${process.env.THEME_COLOR_PRIMARY}-dark no-underline flex items-center w-24`}
          title="5 Star Google Review"
        >
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </a>
        <a
          href={process.env.GOOGLE_REVIEWS_PAGE}
          className={`ml-2 pt-1 no-underline text-grey-darkest hover:text-${process.env.THEME_COLOR_PRIMARY}-darkest hover:underline`}
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          See reviews
        </a>
      </div>
      <p className="text-grey-dark">{process.env.TURNAROUND_PHRASE}</p>
      <div className="flex w-full gap-4 items-center mt-8 lg:mt-10 mb-4">
        <div
          className={`text-lg lg:text-2xl font-medium text-${process.env.THEME_COLOR_PRIMARY}-darker`}
        >
          <Price value={selectedProduct.variationSalePrice} />
        </div>
        <div className="line-through text-lg lg:text-2xl font-medium text-grey">
          <Price value={selectedProduct.variationBasePrice} />
        </div>
        <div
          className={`font-medium uppercase bg-orange-lighter text-orange px-6 py-2 rounded-full`}
        >
          {getSaleText()}
        </div>
      </div>

      <p className="mb-10">
        Sale Ends {props.saleEnds.date} &bull; {props.saleEnds.days} Days Left!
        <span className="text-grey-dark ml-4">*Online Only</span>
      </p>

      <div className="mb-4 leading-loose">
        <h4 className="font-bold">Description</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: selectedProduct.variationShortDescription,
          }}
          className="wp-content inline text-grey-darker leading-loose"
        />
        <DescriptionModal
          header={selectedProduct.variationName}
          description={selectedProduct.variationDescription}
        />
      </div>

      {Object.keys(variationAttributes).map((key, index) => {
        if (
          variationAttributes[key].options.filter((optionName) =>
            isAttributeSelectable(key, optionName)
          ).length <= 1
        )
          return null;
        return (
          <div key={index}>
            <p className="pt-2 pb-4 font-bold">{Case.sentence(key)}</p>
            <div className="flex flex-wrap">
              <select
                name={key}
                id={key}
                className="w-full border border-grey-dark rounded h-10 px-2"
                value={selectedProductAttributes[key]}
                onChange={(e) => {
                  attributeChanged(key, e.currentTarget.value);
                  return true;
                }}
              >
                {variationAttributes[key].options.map(
                  (optionName, optionIndex) => {
                    if (!isAttributeSelectable(key, optionName)) return null;

                    return (
                      <option
                        key={optionIndex}
                        className={"mb-2 capitalize"}
                        value={optionName}
                      >
                        {Case.title(optionName)}
                        {selectedProductAttributes[key] !== optionName &&
                          attributePrice(key, optionName)}
                        {getOutOfStockText(optionName)}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>
        );
      })}
      {selectedProduct.outOfStock &&
        selectedProduct.outOfStockLongDescription && (
          <div className="my-3 p-2 border border-red text-red">
            {selectedProduct.outOfStockLongDescription}
          </div>
        )}
      <div className="flex flex-wrap mt-4">
        <div className="w-full pt-2">
          <div className="flex w-full gap-12 mb-4">
            <div className="flex items-center border border-gray-300 bg-white rounded-lg">
              <button
                className="px-3 py-1 text-xl  "
                onClick={() =>
                  setQuantity((prevQuantity) =>
                    prevQuantity > 1 ? prevQuantity - 1 : 1
                  )
                }
              >
                <FiMinus />
              </button>
              <input
                type="text"
                className="w-12 text-center outline-none"
                value={quantity}
                readOnly
              />
              <button
                className="px-3 py-1 text-xl  "
                onClick={() => setQuantity((prevQuantity) => prevQuantity + 1)}
              >
                <FiPlus />
              </button>
            </div>
            <button
              onClick={() => {
                const productPrice = selectedProduct.variationSalePrice
                  ? selectedProduct.variationSalePrice
                  : selectedProduct.variationBasePrice;

                const eventData = {
                  currency: "USD",
                  value: productPrice * +quantity,
                  items: [
                    {
                      item_name: selectedProduct.variationName,
                      item_id: selectedProduct.variationSku,
                      price: productPrice,
                      item_brand: process.env.COMPANY_NAME,
                      item_category: Array.isArray(
                        props.currentProduct.product.category
                      )
                        ? props.currentProduct.product.category[0]
                        : props.currentProduct.product.category,
                      quantity: +quantity,
                    },
                  ],
                };

                try {
                  window.gtag("event", "add_to_cart", eventData);
                } catch (error) {
                  console.error("gtag error", error);
                }

                try {
                  posthog.capture("add_to_cart", eventData);
                } catch (error) {
                  console.error("posthog error", error);
                }

                const product = {
                  id: selectedProduct.variationSku,
                  skus: (selectedProduct.variationSkus || []).map(
                    (variationSku) => variationSku.variationSkusSku
                  ),
                  taxable: props.currentProduct.product.taxable,
                  title: selectedProduct.variationName,
                  category: props.currentProduct.product.category,
                  basePrice: selectedProduct.variationBasePrice,
                  salePrice: selectedProduct.variationSalePrice,
                  attributes: selectedProduct.variationAttributes,
                  quantity,
                  url: `/${props.currentProduct.slug}/`,
                  image:
                    selectedProduct.variationFeaturedImage.localFile.publicURL,
                  discount: selectedProduct.discounted
                    ? selectedProduct.discountAmount
                    : 0,
                };
                cartContext.addItemToCart(product, false);
                cartContext.setCartDrawerOpenStatus(true);
              }}
              className={classNames(
                `block md:inline-block text-sm px-4 py-3 no-underline rounded h-full uppercase w-full`,
                styles.button.default
              )}
            >
              Add To Cart
            </button>
          </div>
          <div className="flex items-start mb-4">
            <div className="w-full md:w-auto text-sm py-2 px-4 bg-yellow-lightest border border-yellow-light flex text-yellow-darker">
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 60 60"
                className="heroicon-exclamation heroicon heroicon-sm mr-2"
              >
                <path
                  className="heroicon-exclamation-background heroicon-component-fill"
                  d="M33.66 57.14c-2.02-.9-5.29-.9-7.31 0l-.46.2c-2.02.9-4.7.2-6-1.6l-.3-.4a10.17 10.17 0 0 0-6.32-3.66l-.5-.05a5.12 5.12 0 0 1-4.39-4.4l-.05-.5a10.17 10.17 0 0 0-3.66-6.32l-.4-.3a5.12 5.12 0 0 1-1.6-6l.2-.45c.9-2.02.9-5.29 0-7.31l-.2-.46c-.91-2.02-.2-4.7 1.6-6l.4-.3c1.8-1.29 3.43-4.11 3.66-6.32l.05-.5a5.12 5.12 0 0 1 4.4-4.39l.49-.05c2.2-.23 5.03-1.86 6.33-3.66l.3-.4a5.12 5.12 0 0 1 6-1.6l.45.2c2.02.9 5.28.9 7.3 0l.46-.2c2.02-.91 4.7-.2 6 1.6l.3.4c1.3 1.8 4.12 3.43 6.33 3.66l.5.05a5.12 5.12 0 0 1 4.39 4.4l.05.49c.22 2.2 1.85 5.03 3.65 6.33l.4.3a5.12 5.12 0 0 1 1.61 6l-.2.45c-.9 2.02-.9 5.28 0 7.3l.2.46c.9 2.02.2 4.7-1.6 6l-.4.3a10.17 10.17 0 0 0-3.66 6.33l-.05.5a5.12 5.12 0 0 1-4.4 4.39l-.5.05c-2.2.22-5.02 1.85-6.32 3.65l-.3.4a5.12 5.12 0 0 1-6 1.61l-.45-.2z"
                />
                <path
                  className="heroicon-exclamation-mark heroicon-component-accent heroicon-component-fill"
                  d="M25 13h10l-.18 2L33 35h-6l-1.82-20-.18-2zm10 30a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                  style={{ fill: "#f2d024" }}
                />
                <path
                  className="heroicon-shadows"
                  d="M2.68 34.06l-.02.05a5.12 5.12 0 0 0 1.61 6l.4.3c1.8 1.3 3.43 4.13 3.66 6.33l.05.5a5.12 5.12 0 0 0 4.4 4.39l.49.05c2.2.22 5.03 1.86 6.33 3.65l.3.4a5.12 5.12 0 0 0 6 1.61l.45-.2c2.02-.9 5.29-.9 7.3 0l.46.2c2.03.91 4.7.19 6-1.6l.3-.4c1.3-1.8 4.13-3.44 6.33-3.66l.5-.05a5.12 5.12 0 0 0 4.39-4.4l.05-.5a10.17 10.17 0 0 1 3.65-6.32l.4-.3a5.12 5.12 0 0 0 1.61-6l-.02-.05a4.8 4.8 0 0 1-1.58 2.05l-.4.3a10.17 10.17 0 0 0-3.66 6.33l-.05.5a5.12 5.12 0 0 1-4.4 4.39l-.5.05c-2.2.22-5.02 1.85-6.32 3.65l-.3.4a5.12 5.12 0 0 1-6 1.61l-.45-.2c-2.02-.9-5.29-.9-7.31 0l-.46.2c-2.02.9-4.7.2-6-1.6l-.3-.4a10.17 10.17 0 0 0-6.32-3.66l-.5-.05a5.12 5.12 0 0 1-4.39-4.4l-.05-.5a10.17 10.17 0 0 0-3.66-6.32l-.4-.3a4.8 4.8 0 0 1-1.59-2.05zM3.36 28a7.45 7.45 0 0 0-.5-1.65l-.2-.46c-.55-1.22-.5-2.7.02-3.95l.19.4c.68 1.54.85 3.79.5 5.66zm53.96-6.06c.52 1.26.57 2.72.02 3.95l-.2.46a7.46 7.46 0 0 0-.5 1.65c-.35-1.88-.19-4.12.5-5.65l.18-.4z"
                />
                <path
                  className="heroicon-outline"
                  fillRule="nonzero"
                  d="M33.66 57.14c-2.02-.9-5.29-.9-7.31 0l-.46.2c-2.02.9-4.7.2-6-1.6l-.3-.4a10.17 10.17 0 0 0-6.32-3.66l-.5-.05a5.12 5.12 0 0 1-4.39-4.4l-.05-.5a10.17 10.17 0 0 0-3.66-6.32l-.4-.3a5.12 5.12 0 0 1-1.6-6l.2-.45c.9-2.02.9-5.29 0-7.31l-.2-.46c-.91-2.02-.2-4.7 1.6-6l.4-.3c1.8-1.29 3.43-4.11 3.66-6.32l.05-.5a5.12 5.12 0 0 1 4.4-4.39l.49-.05c2.2-.23 5.03-1.86 6.33-3.66l.3-.4a5.12 5.12 0 0 1 6-1.6l.45.2c2.02.9 5.28.9 7.3 0l.46-.2c2.02-.91 4.7-.2 6 1.6l.3.4c1.3 1.8 4.12 3.43 6.33 3.66l.5.05a5.12 5.12 0 0 1 4.39 4.4l.05.49c.22 2.2 1.85 5.03 3.65 6.33l.4.3a5.12 5.12 0 0 1 1.61 6l-.2.45c-.9 2.02-.9 5.28 0 7.3l.2.46c.9 2.02.2 4.7-1.6 6l-.4.3a10.17 10.17 0 0 0-3.66 6.33l-.05.5a5.12 5.12 0 0 1-4.4 4.39l-.5.05c-2.2.22-5.02 1.85-6.32 3.65l-.3.4a5.12 5.12 0 0 1-6 1.61l-.45-.2zm.82-1.83l.45.2c1.16.53 2.82.08 3.56-.95l.3-.4a12.14 12.14 0 0 1 7.74-4.47l.5-.05a3.13 3.13 0 0 0 2.6-2.61l.06-.5a12.13 12.13 0 0 1 4.47-7.74l.4-.3a3.13 3.13 0 0 0 .96-3.56l-.2-.45a12.13 12.13 0 0 1 0-8.95l.2-.45a3.13 3.13 0 0 0-.96-3.57l-.4-.29a12.14 12.14 0 0 1-4.47-7.75l-.05-.5a3.13 3.13 0 0 0-2.61-2.6l-.5-.05a12.13 12.13 0 0 1-7.74-4.47l-.3-.4a3.13 3.13 0 0 0-3.56-.96l-.45.2a12.13 12.13 0 0 1-8.95 0l-.45-.2a3.13 3.13 0 0 0-3.57.95l-.29.4a12.13 12.13 0 0 1-7.75 4.48l-.5.05a3.13 3.13 0 0 0-2.6 2.6l-.05.5a12.13 12.13 0 0 1-4.47 7.75l-.4.3a3.13 3.13 0 0 0-.96 3.56l.2.45a12.13 12.13 0 0 1 0 8.95l-.2.45a3.13 3.13 0 0 0 .95 3.56l.4.3a12.13 12.13 0 0 1 4.48 7.74l.05.5a3.13 3.13 0 0 0 2.6 2.6l.5.06c2.78.28 6.12 2.21 7.75 4.47l.3.4a3.13 3.13 0 0 0 3.56.96l.45-.2a12.13 12.13 0 0 1 8.95 0zM25 13.01h10l-.18 2L33 35h-6l-1.82-20-.18-2zm3.83 20h2.35l1.63-18H27.2l1.64 18zM35 43a5 5 0 1 1-10 0 5 5 0 0 1 10 0zm-5 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM10 30a20 20 0 0 1 11.06-17.9l.44.9A19 19 0 0 0 13 38.5l-.89.44a19.92 19.92 0 0 1-2.1-8.95z"
                  style={{ fill: "#f2d024" }}
                />
              </svg>
              <p>
                <SupDollarSign />
                100 Deposit to start your order today
                <br />
                Deposit is applied to your total balance
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

VariableProductLinerTemplate.propTypes = {
  productAttributes: PropTypes.arrayOf(PropTypes.object),
};

export default VariableProductLinerTemplate;
